//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CookieNotification.',
  components: {
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton')
  },
  props: {
    detailsLinkText: {
      type: String,
      required: true
    },
    detailsLink: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  }
};
