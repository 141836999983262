//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, reactive, toRefs, computed, watch, onMounted, useContext, onUnmounted } from '@nuxtjs/composition-api';
import { parseISO, isFuture, intervalToDuration } from 'date-fns';
import { formatGtm, useStoryblokGlobalComponents, useStoryblokPage, useCategory } from '@odyssey/realtainment';
export default {
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  setup() {
    const { $gtm, route } = useContext();
    const { globalComponents } = useStoryblokGlobalComponents();
    const { pageData } = useStoryblokPage();
    const { categories } = useCategory();

    const isActive = ref(false);
    const state = reactive({
      durationUntilEndDate: null,
      durationUpdateInterval: null
    });

    const notificationsData = computed(() => globalComponents.value.notificationBars);
    // Notification assigned to page has priority over one assigned globally
    const selectedNotification = computed(() => {
      const pageNotification = pageData.value?.notification;
      const globalNotification = notificationsData.value?.globalNotification;

      return notificationsData.value?.items?.find((i) => i.id === (pageNotification || globalNotification));
    });

    const endDate = computed(() =>
      selectedNotification.value?.endDate ? parseISO(selectedNotification.value?.endDate) : null
    );

    const notificationKey = computed(() => `notification-bar-${selectedNotification.value?.id}`);

    const isValid = computed(() => {
      if (!selectedNotification.value) return false;
      if (endDate.value !== null) return isFuture(endDate.value);
    });

    function setClaim() {
      const wasClosed = process.client ? localStorage.getItem(notificationKey.value) : null;
      isActive.value = wasClosed === null && isValid.value;
    }

    function onClose() {
      localStorage.setItem(notificationKey.value, true);
      isActive.value = false;
      clearInterval(state.durationUpdateInterval);
    }

    function padDateFragment(number) {
      return `${number}`.padStart(2, '0');
    }

    function onCtaClick() {
      if (selectedNotification.value?.label) {
        $gtm.push(
          formatGtm('all/PROMOTION_SELECT', {
            promotion_id: selectedNotification.value?.id,
            creative_name: selectedNotification.value?.creativeName,
            location_id: route.value.meta.pageType
          })
        );
        window.location.href = selectedNotification.value?.link;
      }
    }

    onMounted(() => setClaim());

    onUnmounted(() => {
      clearInterval(state.durationUpdateInterval);
    });

    function setDurationObject() {
      state.durationUntilEndDate = intervalToDuration({ start: new Date(), end: endDate.value });
    }
    watch(isActive, (value) => {
      if (isValid.value) {
        if (value && selectedNotification.value.showCounter && process.client) {
          // If showCounter is true, we'll update the counter every second while the notification isActive
          setDurationObject();
          state.durationUpdateInterval = setInterval(setDurationObject, 1000);
        } else clearInterval(state.durationUpdateInterval);
      }
    });

    const slug = computed(() => route.value.params.slug);
    const isCategoryRoute = computed(() => categories.value.find((c) => c.slug === slug.value) !== undefined);

    const displayNotificationOnApprovedPages = computed(() => {
      const isCityPage = computed(() => isCategoryRoute.value && route.value?.path !== '/online-events/');
      const currenPage = computed(() => (isCityPage.value ? '/city/' : route.value?.path));

      return !selectedNotification.value?.excludedPages?.includes(currenPage.value);
    });

    return {
      ...toRefs(state),
      isActive,
      notificationsData,
      selectedNotification,
      onClose,
      padDateFragment,
      onCtaClick,
      displayNotificationOnApprovedPages
    };
  }
};
