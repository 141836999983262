import { render, staticRenderFns } from "./SNotificationBar.vue?vue&type=template&id=2945d6bf"
import script from "./SNotificationBar.vue?vue&type=script&lang=js"
export * from "./SNotificationBar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports