//
//
//
//
//
//
//
//
//
//
//
//

import useUiNotification from '@odyssey/realtainment-theme/composables/useUiNotification';
export default {
  name: 'SNotification',
  setup() {
    const { notifications } = useUiNotification();
    return {
      notifications
    };
  }
};
